<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar>
          <template #start>
            
          
              <Dropdown
                id="status"
                
                v-model="submissionstatus"
                :options="submissionItems"
                :value="submissionItems.value"
                optionLabel="name"
                placeholder="Select Course"
                class="my-select mr-2"
                required="true"
                v-on:change="get_list"
              ></Dropdown>
           
          </template>

          <template #end> </template>
        </Toolbar>
        <div class="grid">
          <div class="col-12 lg:col-6 xl:col-3">
            <div class="card mb-0">
              <div class="flex justify-content-between mb-3">
                <div>
                  <span class="block text-500 font-medium mb-3"
                    >Total No Of Lecture</span
                  >
                  <div class="text-900 font-medium text-xl"><b style="color:green">{{ TNL }}</b></div>
                </div>
                <div
                  class="
                    flex
                    align-items-center
                    justify-content-center
                    bg-blue-100
                    border-round
                  "
                  style="width: 2.5rem; height: 2.5rem"
                >
                  <i class="pi pi-fw pi-thumbs-up text-blue-500 text-xl"></i>
                </div>
              </div>
              <!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
            </div>
          </div>

          <div class="col-12 lg:col-6 xl:col-3">
            <div class="card mb-0">
              <div class="flex justify-content-between mb-3">
                <div>
                  <span class="block text-500 font-medium mb-3"
                    >Total Question Ask</span
                  >
                  <div class="text-900 font-medium text-xl"><b style="color:green">{{ TQAsk }}</b></div>
                </div>
                <div
                  class="
                    flex
                    align-items-center
                    justify-content-center
                    bg-blue-100
                    border-round
                  "
                  style="width: 2.5rem; height: 2.5rem"
                >
                  <i
                    class="pi pi-fw pi-question-circle text-blue-500 text-xl"
                  ></i>
                </div>
              </div>
              <!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
            </div>
          </div>

          <div class="col-12 lg:col-6 xl:col-3">
            <div class="card mb-0">
              <div class="flex justify-content-between mb-3">
                <div>
                  <span class="block text-500 font-medium mb-3"
                    >Total Questions Answer</span
                  >
                  <div class="text-900 font-medium text-xl"><b style="color:green">{{ TQAnswer }}</b></div>
                </div>
                <div
                  class="
                    flex
                    align-items-center
                    justify-content-center
                    bg-blue-100
                    border-round
                  "
                  style="width: 2.5rem; height: 2.5rem"
                >
                  <i class="pi pi-fw pi-unlock text-blue-500 text-xl"></i>
                </div>
              </div>
              <!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
            </div>
          </div>
        </div>

        <!-- ------------------------------------------------------------------------------------------------------------------ -->

        <Accordion class="accordion-custom">
          <AccordionTab>
            <template #header>
              <span><b>No of Lectures</b></span>
            </template>
            <DataTable
              :loading="loading"
              ref="dt"
              :lazy="true"
              :totalRecords="totalRecords"
              :paginator="true"
              :value="products"
              v-model:selection="selectedProducts"
              :dataKey="columns[0]"
              :rows="limit"
              :filters="filters"
              @page="onPage($event)"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              :rowsPerPageOptions="[5, 10, 25, totalRecords]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              responsiveLayout="scroll"
              style="font-size: 12px"
              :rowHover="true" showGridlines
            >
            <template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
              <Column
                header="Schedule"
                :field="columns[2]"
                style="min-width: 10rem"
              >
                <template #body="{ data }">
                  {{ data.schedule }}
                </template>
              </Column>
              <Column
                header="Teacher Name"
                :field="columns[2]"
                style="min-width: 10rem"
              >
                <template #body="{ data }">
                  {{ data.teacherName }}
                </template>
              </Column>
              <Column
                header="Cource Name"
                :field="columns[2]"
                style="min-width: 10rem"
              >
                <template #body="{ data }">
                  {{ data.courcename }}
                </template>
              </Column>
              <Column
                header="Subject Name"
                :field="columns[2]"
                style="min-width: 10rem"
              >
                <template #body="{ data }">
                  {{ data.subjectname }}
                </template>
              </Column>
              <Column
                header="Discuession On Topic"
                :field="columns[2]"
                style="min-width: 10rem"
              >
                <template #body="{ data }">
                  {{ data.discuessioN_ON_TOPIC }}
                </template>
              </Column>
            </DataTable>
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <span><b>Ask Questions</b> </span>
            </template>
            <DataTable
              :loading="loading"
              ref="dt"
              :lazy="true"
              :totalRecords="totalRecords"
              :paginator="true"
              :value="products2"
              v-model:selection="selectedProducts"
              :dataKey="columns[0]"
              :rows="limit"
              :filters="filters"
              @page="onPage($event)"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              :rowsPerPageOptions="[5, 10, 25, totalRecords]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              responsiveLayout="scroll"
              style="font-size: 12px"
               :rowHover="true" showGridlines
            >
            <template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
              <Column
                header="Teacher Name"
                :field="columns[2]"
                style="min-width: 10rem"
              >
                <template #body="{ data }">
                  {{ data.teacherName }}
                </template>
              </Column>
              <Column
                header="Subject Name"
                :field="columns[2]"
                style="min-width: 10rem"
              >
                <template #body="{ data }">
                  {{ data.subjectname }}
                </template>
              </Column>

              <Column
                header="Topic"
                :field="columns[2]"
                style="min-width: 10rem"
              >
                <template #body="{ data }">
                  {{ data.topic }}
                </template>
              </Column>

              <Column
                header="Doubt"
                :field="columns[2]"
                style="min-width: 10rem"
              >
                <template #body="{ data }">
                  {{ data.doubt }}
                </template>
              </Column>

              <Column
                header="View Answer"
                :field="columns[2]"
                style="min-width: 10rem"
              >
                <template #body="{ data }">
                  {{ data.viewAnswer }}
                </template>
              </Column>
              <Column
                header="Answer Time"
                :field="columns[2]"
                style="min-width: 10rem"
              >
                <template #body="{ data }">
                  {{ data.answerTime }}
                </template>
              </Column>
              <Column
                header="View"
                :field="columns[2]"
                style="min-width: 10rem"
              >
                <template #body="{ data }">
                  <Button
                    v-if="data.viewAnswer == 'Yes'"
                    label="View Answer"
                    class="p-button-outlined"
                    @click="viewanswer(data)"
                  />
                </template>
              </Column>
            </DataTable>
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <span><b>FAQ</b> </span>
            </template>
            <p></p>
          </AccordionTab>
        </Accordion>

        <!-- ------------------------------------------------------------------------------------------------------------------ -->

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '800px', width: '800px' }"
          header="Form on Ask a Doubt"
          :modal="true"
          class="p-fluid"
        >
          <div class="col-12 md:col-12">
            <div class="field">
              <label for="doubt">Doubt</label>
              <Textarea
                id="doubt"
                v-model.trim="viewans.doubt"
                required="true"
                autofocus
                rows="2"
                disabled
                :class="{
                  'p-invalid': submitted && !viewans.doubt,
                }"
              />
            </div>
          </div>
          <div class="col-12 md:col-12">
            <div class="field">
              <label for="doubt">Answer</label>
              <Textarea
                id="doubt"
                v-model.trim="viewans.answer"
                required="true"
                autofocus
                rows="5"
                disabled
                :class="{
                  'p-invalid': submitted && !viewans.doubt,
                }"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-6">
              <div class="field">
                <label for="doubT_TIME">Doubt Time</label>
                <InputText
                  id="doubT_TIME"
                  v-model.trim="viewans.doubT_TIME"
                  required="true"
                  autofocus
                  disabled
                  :class="{
                    'p-invalid': submitted && !viewans.doubT_TIME,
                  }"
                />
              </div>
            </div>

            <div class="col-12 md:col-6">
              <div class="field">
                <label for="answeR_TIME">Answer Time</label>
                <InputText
                  id="answeR_TIME"
                  v-model.trim="viewans.answeR_TIME"
                  required="true"
                  autofocus
                  disabled
                  :class="{
                    'p-invalid': submitted && !viewans.answeR_TIME,
                  }"
                />
              </div>
            </div>
          </div>
          <hr />
          <div class="col-12 md:col-12">
            <h5>Ask Again</h5>
            <DataTable
              :value="ask_again"
              style="font-size: 12px"
              :paginator="true"
              class="p-datatable-gridlines"
              :rows="5"
              dataKey="id"
              :rowHover="true"
              v-model:filters="filters1"
              filterDisplay="menu"
              :loading="loading1"
              :filters="filters1"
              responsiveLayout="scroll"
              :totalRecords="ask_again.length"
            >
              <Column
                header="Ask Time"
                style="min-width: 6rem"
                :field="columns[0]"
              >
                <template #body="{ data }">
                  {{ data.doubT_TIME }}
                </template>
              </Column>
              <Column
                header="Doubt"
                style="min-width: 6rem"
                :field="columns[0]"
              >
                <template #body="{ data }">
                  {{ data.doubt }}
                </template>
              </Column>
              <Column
                header="Answer"
                style="min-width: 6rem"
                :field="columns[0]"
              >
                <template #body="{ data }">
                  {{ data.answer }}
                </template>
              </Column>
            </DataTable>
          </div>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteVideo"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete the selected products?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      ask_again: null,
      ask_q: false,
      TQAsk: 0,
      TQAnswer: 0,
      TNL: 0,
      uploadproductDialog: false,
      stdDialog: false,
      submissionItems: [],
      submissionstatus: { name: "", value: "" },

      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      
      dropdownItems: [
        { name: "YES", value: "YES" },
        { name: "NO", value: "NO" },
      ],

      stdproducts: null,
      products: null,
      products2: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      viewans: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "", value: "" },
      selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.get_submission_dropdown();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.products2 = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    viewanswer(info) {
      console.log(info);
      // this.viewans={...data};
      // this.productDialog=true;
      var data = {
        MJAD_ID: info.mjaD_ID,
      };
      this.loading = true;
      var promise = apis.viewdoubt(data);
      promise.then((response) => {
        this.loading = false;
        this.viewans = response.data.askdoubt[0];
        this.ask_again = response.data.askdoubtagain;
        this.productDialog = true;
      });
    },
    view_video(video) {
      // alert(video)
      window.open(video.data.embeD_LINK, "_blank");
    },
    view_std(info) {
      var data = {
        TSCH_DE_ID: info.tscH_DE_ID,
      };
      this.loading = true;
      var promise = apis.lectureattendedstudentlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        this.stdproducts = response.data;
        this.stdDialog = true;
      });
    },
    get_submission_dropdown: function () {
      var data = {
        //  "TEACHER_ID":parseInt(localStorage.getItem("id"))
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.batchlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);

        var info = response.data;
        if (info) {
          this.submissionItems = [];
          for (var i = 0; i < info.length; i++) {
            this.submissionItems.push({
              name: info[i].batch,
              value: info[i].mjmB_ID,
            });
          }
        }
      });
    },
    //a simple date formatting function
    dateFormat(inputDate, info) {},
   
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      if (this.submissionstatus.value) {
        var data = {
          //id:parseInt(localStorage.getItem("id")),

          batch: this.submissionstatus.value,
        };
        this.loading = true;
        var promise = apis.askdoubtreport(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.totalRecords = response.data.askdoubtlectures.length;
          //alert(this.totalRecords);
        });
      }
    },
    refresh() {
      this.product = null;
      this.submissionstatus = { name: "", value: "" };
    },
    async get_list() {
      if (this.submissionstatus.value) {
        var data = {
          batch: this.submissionstatus.value,
          // Id: 181,
        };
        this.isLoadingModel = true;
        var promise = apis.askdoubtreport(data, this.id);
        promise.then((response) => {
          this.isLoadingModel = false;
          console.log(response);
          this.products = response.data.askdoubtlectures;
          this.products2 = response.data.askquestions;

          this.TNL = response.data.nooflectures;
          this.TQAnswer = response.data.noofanswers;
          this.TQAsk = response.data.noofquestions;
          console.log(this.products[0]);
          if (this.columns.length == 0) {
            if (this.products.length > 0) {
              this.columns = Object.keys(this.products[0]);
            }
          }
        });
        await this.get_count();
      }
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    async Updatesubmission() {
      this.submitted = true;
      if (!this.selectstatus.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please select check by teacher",
          life: 3000,
        });
        return false;
      }
      if (!this.product.teacheR_REMARK) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Remark",
          life: 3000,
        });
        return false;
      }

      //edit
      if (this.product.tscH_DE_ID) {
        var data = {
          TSCH_DE_ID: this.product.tscH_DE_ID,
          STUDENT_ID: this.product.studenT_ID,
          TEACHER_REMARK: this.product.teacheR_REMARK,
          TEACHER_CHECK: this.selectstatus.value,
        };
        this.isLoadingModel = true;
        var promise = apis.updateteacherremark(data);
        promise
          .then((responseapi) => {
            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

      this.selectstatus = { name: "", value: "" };
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    editProduct(product) {
      this.product = { ...product };
      this.productDialog = true;
      // this.selectstatus.value = this.product.type;
      // this.selectstatus.name = this.product.type;

      // this.selectstatus2.value = this.product.status;
      // this.selectstatus2.name = this.product.status;

   
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteVideo() {
      if (this.product.id) {
        var data = {
          Id: this.product.id,
        };
        this.isLoadingModel = true;
        var promise = apis.deletebatchmaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.status == 200) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
.my-select {
 min-width: 12em;
}
</style>
